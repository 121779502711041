<template>
	<div class="pui-form">
		<equipamiento-modals
			:modelName="modelName"
		></equipamiento-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement || showParentModelHeader">
			<notificacion-form-header v-if="showParentModelHeader" :modelPk="modelPk"></notificacion-form-header>			
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
                    <pui-field-set :title="getTitleDatos">
                        <v-layout wrap>
							<!-- IDNOTIFEQUIP -->
							<v-flex xs12 md1 >
								<pui-text-field
									:id="`idnotifequip-equipamiento`"
									v-model="model.idnotifequip"
									:label="$t('equipamiento.idnotifequip')"
									disabled									
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- IDNOTIF -->
							<v-flex xs12 md4 v-if="!showParentModelHeader">
								<pui-select
									id="idnotif"
									attach="idnotif"
									:label="$t('equipamiento.idnotif')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupnotificacion"
									:itemsToSelect="[{ id: model.idnotif }]"
									:modelFormMapping="{ id: 'idnotif' }"
									itemValue="id"
									:itemText="item => `${item.numdoc} - ${item.estado}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
                        </v-layout>

						<v-layout wrap>
							<!-- IDTIPEQUIP -->
							<v-flex xs12 md4>								
								<pui-select
									id="idtipequip"
									attach="idtipequip"
									:label="$t('equipamiento.idtipequip')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmtipoequipamiento"
									:itemsToSelect="[{ id: model.idtipequip }]"
									:modelFormMapping="{ id: 'idtipequip' }"
									itemValue="id"
									:itemText="item => `${item.name} - ${item.codedi}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>

						<v-layout wrap>
							<!-- MATRICULA -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`matricula-equipamiento`"
									v-model="model.matricula"
									:label="$t('equipamiento.matricula')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="17"
								></pui-text-field>
							</v-flex>
							<!-- MATESISO -->
							<v-flex xs12 md1 align-self-center>								
								<pui-checkbox
									:label="$t('equipamiento.matesiso')"
									v-model="model.matesiso"
									true-value="S"
									false-value="N"									
									:disabled="formDisabled"
								></pui-checkbox>
							</v-flex>
							<!-- PESOBRUTO -->
							<v-flex xs12 md1>
								<pui-number-field
									:id="`pesobruto-equipamiento`"
									v-model="model.pesobruto"
									:label="$t('equipamiento.pesobruto')"
									:disabled="formDisabled"
									required
									toplabel									
									min="0"
									max="999999999999999999"
								></pui-number-field>								
							</v-flex>
						</v-layout>

						<v-layout wrap>
							<!-- IDTIPTAMEQUIP -->
							<v-flex xs12 md4>								
								<pui-select
									id="idtiptamequip"
									attach="idtiptamequip"
									:label="$t('equipamiento.idtiptamequip')"
									toplabel
									clearable
									:required="esTipoContenedor"
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmtipytamequipamiento"
									:itemsToSelect="[{ id: model.idtiptamequip }]"
									:modelFormMapping="{ id: 'idtiptamequip' }"
									itemValue="id"
									:itemText="item => `${item.name} - ${item.codedi}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
							<!-- IDGRUEQUIP -->
							<v-flex xs12 md4>							
								<pui-select
									id="idgruequip"
									attach="idgruequip"
									:label="$t('equipamiento.idgruequip')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmgrupajeequipamiento"
									:itemsToSelect="[{ id: model.idgruequip }]"
									:modelFormMapping="{ id: 'idgruequip' }"
									itemValue="id"
									:itemText="item => `${item.name} - ${item.codedi}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>						
						</v-layout>
						
                        <!-- MINI AUDIT -->
                        <pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
                    </pui-field-set>
                </v-flex>
            </v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import equipamientoActions from './EquipamientoActions';
import equipamientoModals from './EquipamientoModals.vue';

export default {
	name: 'equipamiento-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'equipamiento-modals': equipamientoModals
	},
	data() {
		return {
			modelName: 'equipamiento', 
			actions: equipamientoActions.formActions,
			parentModelName: 'notificacion',
			showParentModelHeader: false
		};
	},
	methods: {		
		afterGetData() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel != null) {
				const pkParent = parentModel.pk;
				this.model.idnotif = pkParent.idnotif;
				this.showParentModelHeader = true;				
			}
		}		
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.equipamiento');
		},
		esTipoContenedor() {
			return (
				this.model.idtipequip == '12'
			);
		}
	},
	created() {
	}
}
</script>
